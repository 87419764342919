import React from "react";
import { Button, Icon, Transition, TransitionGroup } from "semantic-ui-react";

export default function ExternalLinks() {
  const [open, setOpen] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  function toggleVisibility() {
    setOpen(!open);
    setVisible(!visible);
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Button
        fluid
        onClick={toggleVisibility}
        className={"faq-button" + (visible ? " opened" : "")}
        basic
      >
        <span>External Links</span>
        <div className="trw">
          <Transition
            visible={visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon
              color={visible ? "green" : "black"}
              name="angle up"
              size="large"
            />
          </Transition>
        </div>
        <div className="trw">
          <Transition
            visible={!visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon name="angle down" size="large" />
          </Transition>
        </div>
      </Button>
      <TransitionGroup animation="slide down" duration={500}>
        {open && (
          <ul>
            <li>
              We are now Verified on&mdash;
              <ul>
                <li>
                  <a
                    href="https://www.jpg.store/collection/64be5b4909fc1f08f9f163d42b923bfb2d2b080dd84c818ebdbdbc28?tab=items"
                    target="_blank"
                    rel="noreferrer"
                  >
                    JPG.STORE
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        )}
      </TransitionGroup>
    </div>
  );
}
