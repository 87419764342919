import React, { useState } from "react";
import {
  Button,
  Container,
  Divider,
  Icon,
  Transition,
} from "semantic-ui-react";

import data from "../data/promotion.json";

// const promotionImages = [
//   "0069.gif",
//   "0420.gif",
//   "0777.gif",
//   "1234.png",
//   "6969.gif",
// ];

export default function Promotions({ onImageModalOpen }) {
  const [visible, setVisible] = useState(false);

  function toggleVisibility() {
    setVisible(!visible);
  }
  return (
    <Container
      text
      fluid
      className={"promotion-container " + (visible ? "opened" : "")}
    >
      <Button
        fluid
        onClick={toggleVisibility}
        className={"faq-button opened"}
        style={{ marginBottom: 0 }}
        basic
      >
        <span>Promotions</span>
        <div className="trw">
          <Transition
            visible={visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon color="green" name="angle up" size="large" />
          </Transition>
        </div>
        <div className="trw">
          <Transition
            visible={!visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon color="green" name="angle down" size="large" />
          </Transition>
        </div>
      </Button>
      <Transition
        visible={visible}
        animation="fade up"
        duration={400}
        unmountOnHide
      >
        <Container className="promotion" fluid>
          {/* <p>
            Find one of the <span className="pink">5 $SNEK</span> OOZ1ES below &
            receive <span className="pink">25,000 $SNEK</span>
          </p>
          <div class="marquee-container">
            <div class="marquee">
              {promotionImages.map((id, idx) => (
                <button
                  key={id}
                  className="imgButton"
                  onClick={() =>
                    onImageModalOpen(
                      data[idx],
                      "https://ooz1es.com/ft/pictures/ooz1es" + id
                    )
                  }
                >
                  <img
                    src={"https://ooz1es.com/ft/pictures/ooz1es" + id}
                    alt="OOZ1ES #####"
                    className="oozie"
                  />
                </button>
              ))}
            </div>
            <div class="marquee marquee2">
              {promotionImages.map((id, idx) => (
                <button
                  key={id}
                  className="imgButton"
                  onClick={() =>
                    onImageModalOpen(
                      data[idx],
                      "https://ooz1es.com/ft/pictures/ooz1es" + id
                    )
                  }
                >
                  <img
                    src={"https://ooz1es.com/ft/pictures/ooz1es" + id}
                    alt="OOZ1ES #####"
                    className="oozie"
                  />
                </button>
              ))}
            </div>
          </div>
          <Divider /> */}
          <p>
            Find an OOZ1ES with a SKULL or SKULL ON FIRE hat and receive{" "}
            <strong className="pink">200 $SKULL</strong>{" "}
            <span role="img">💀</span> token from the{" "}
            <a href="https://twitter.com/_nemonium">Nemonium</a> community!
          </p>
          <div class="imgContainer">
            <button
              className="imgButton"
              onClick={() =>
                onImageModalOpen(
                  data[9],
                  "https://ooz1es.com/ft/pictures/ooz1es0115.gif"
                )
              }
            >
              <img
                src="https://ooz1es.com/ft/pictures/ooz1es0115.gif"
                alt="OOZ1ES #####"
                className="oozie"
              />
            </button>
            <button
              className="imgButton"
              onClick={() =>
                onImageModalOpen(
                  data[8],
                  "https://ooz1es.com/ft/pictures/ooz1es0155.gif"
                )
              }
            >
              <img
                src="https://ooz1es.com/ft/pictures/ooz1es0155.gif"
                alt="OOZ1ES #####"
                className="oozie"
              />
            </button>
          </div>
          <Divider />
          <p>
            Find <strong className="pink">1</strong> of the{" "}
            <strong className="pink">10</strong> marble hats and you will
            receive <strong className="pink">1,000,000</strong> of the new meme
            coin coming soon from{" "}
            <a href="https://toiletology.org">The Church of Toiletology</a>!
          </p>
          <div class="imgContainer">
            <button
              className="imgButton"
              onClick={() =>
                onImageModalOpen(
                  data[7],
                  "https://ooz1es.com/ft/pictures/ooz1es7286.png"
                )
              }
            >
              <img
                src="https://ooz1es.com/ft/pictures/ooz1es7286.png"
                alt="OOZ1ES #####"
                className="oozie"
              />
            </button>
          </div>
          <Divider />
          <p>
            At the end of the sale whoever has minted the most OOZ1ES will get
            this 1/1 Ultimate NFT <strong className="pink">OOZ1ES #9999</strong>
          </p>
          <div class="imgContainer">
            <button
              className="imgButton"
              onClick={() =>
                onImageModalOpen(
                  data[6],
                  "https://ooz1es.com/ft/pictures/ooz1es9999.gif"
                )
              }
            >
              <img
                src="https://ooz1es.com/ft/pictures/ooz1es9999.gif"
                alt="OOZ1ES #####"
                className="oozie"
              />
            </button>
          </div>
        </Container>
      </Transition>
    </Container>
  );
}
