import React from "react";
import { TransitionGroup, Transition, Icon, Button } from "semantic-ui-react";
import skull from "../images/skull_pool.gif";
import anti from "../images/anti-biz.png";

export default function Partners() {
  const [open, setOpen] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  function toggleVisibility() {
    setOpen(!open);
    setVisible(!visible);
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Button
        fluid
        onClick={toggleVisibility}
        className={"faq-button" + (visible ? " opened" : "")}
        basic
      >
        <span>Partners</span>
        <div className="trw">
          <Transition
            visible={visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon
              color={visible ? "green" : "black"}
              name="angle up"
              size="large"
            />
          </Transition>
        </div>
        <div className="trw">
          <Transition
            visible={!visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon name="angle down" size="large" />
          </Transition>
        </div>
      </Button>
      <TransitionGroup animation="slide down" duration={500}>
        {open && (
          <div className="partners">
            <a
              href="https://cexplorer.io/pool/pool1h0v99elmvl50rqk778ue6g49r3mtgq3z4draq6pzy0nqv000frd"
              target="_blank"
              rel="noreferrer"
            >
              <img src={skull} alt="skull 💀" />
            </a>
            <a href="https://anti.biz" target="_blank" rel="noreferrer">
              <img src={anti} alt="anti.biz" />
            </a>
            <div className="socials p">
              <a href="https://twitter.com/antibiz_">
                <Icon name="twitter" />
              </a>
              <a href="https://discord.gg/qFd5BsfGu3">
                <Icon name="discord" />
              </a>
              <a href="https://www.instagram.com/anti.biz/">
                <Icon name="instagram" />
              </a>
            </div>
          </div>
        )}
      </TransitionGroup>
    </div>
  );
}
