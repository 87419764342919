import React from "react";
import { Modal } from "semantic-ui-react";

export default function MintModal({ open, onClose }) {
  const [copied, setCopied] = React.useState(0);

  function copyAddress(ah = 0) {
    let address = "addr1v9ean3pyz7a26kyuxq4xnpj0v5avm84e5u6c7938pstegjc27cuxh";
    if (ah === 1) address = "$ooz1es";
    if (navigator.clipboard) {
      navigator.clipboard.writeText(address);
      setCopied(ah === 1 ? 2 : 1);
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = address;
      textArea.style.position = "fixed";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
        setCopied(ah === 1 ? 2 : 1);
      } catch (err) {
        console.error("Unable to copy", err);
      }
      document.body.removeChild(textArea);
    }
    setTimeout(() => {
      setCopied(0);
    }, 3000);
  }

  return (
    <Modal
      size="small"
      style={{ background: "#151515" }}
      open={open}
      dimmer="blurring"
      onClose={onClose}
    >
      <Modal.Content
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#151515",
        }}
      >
        <p className="mint-text" style={{ marginBottom: 4 }}>
          <strong>ADAhandle</strong>
        </p>
        <div
          onClick={() => copyAddress(1)}
          onKeyDown={() => copyAddress(1)}
          title="Click to Copy"
          role="button"
          tabIndex={0}
          className="address"
        >
          $ooz1es
        </div>
        {copied === 2 ? (
          <span className="primary">Copied!</span>
        ) : (
          <span className="dimmed">Click to Copy</span>
        )}
        <p className="mint-text" style={{ marginBottom: 4 }}>
          In case you can't use an <strong>ADAhandle</strong>
        </p>
        <div
          onClick={copyAddress}
          onKeyDown={copyAddress}
          title="Click to Copy"
          role="button"
          tabIndex={0}
          className="address"
        >
          addr1v9ean3pyz7a26kyuxq4xnpj0v5avm84e5u6c7938pstegjc27cuxh
        </div>
        {copied === 1 ? (
          <span className="primary">Copied!</span>
        ) : (
          <span className="dimmed">Click to Copy</span>
        )}
        <p className="mint-text">
          Please send <span className="pink">35 ₳ADA</span> to any ONE of the
          addresses above & you will receive{" "}
          <span className="pink">15 ₳ADA</span> back +{" "}
          <span className="pink">10</span> OOZ1ES!
        </p>
      </Modal.Content>
    </Modal>
  );
}
